<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Funcionarios</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Administrativo</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Funcionarios</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="col-6 pt-2 pb-2">
                  <h5 class="mb-0">Formulario {{ accion }} Funcionarios</h5>
                </div>
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="tab-Principal"
                      data-toggle="tab"
                      href="#Principal"
                      >Principal</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="tab-Documentos"
                      data-toggle="tab"
                      href="#Documentos"
                      v-if="form.id"
                      >Documentos</a
                    >
                  </li>
                </ul>
              </div>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade active show" id="Principal">
                  <div class="card-body">
                    <div class="row">
                      <div
                        class="col-md-9"
                        :class="form.id ? 'col-md-9' : 'col-md-12'"
                      >
                        <div class="card-body p-0">
                          <div class="row">
                            <div class="form-group col-md-2">
                              <label for="n_identificacion"># Documento</label>
                              <input
                                type="number"
                                :disabled="opcion == 1 || form.id"
                                class="form-control form-control-sm"
                                id="numero_documento"
                                v-model="form.numero_documento"
                                :class="
                                  $v.form.numero_documento.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                @blur="validaFuncionario"
                              />
                            </div>
                            <div class="form-group col-md-2">
                              <label for="tipo_documento"
                                >Tipo identificación</label
                              >
                              <select
                                class="form-control form-control-sm"
                                v-model="form.tipo_documento"
                                :disabled="opcion == 1 || form.id"
                                :class="
                                  $v.form.tipo_documento.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              >
                                <option value="">Seleccione...</option>
                                <option
                                  v-for="tipo_documento in listasForms.tipo_identificacion"
                                  :key="tipo_documento.numeracion"
                                  :value="tipo_documento.numeracion"
                                >
                                  {{ tipo_documento.descripcion }}
                                </option>
                              </select>
                            </div>
                            <div class="form-group col-md-3">
                              <label for="nombres">Nombres</label>
                              <input
                                type="text"
                                :disabled="opcion == 1"
                                class="form-control form-control-sm"
                                id="nombres"
                                v-model="form.nombres"
                                @input="validarLetra(form.nombres, 1)"
                                :class="
                                  $v.form.nombres.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                            </div>
                            <div class="form-group col-md-3">
                              <label for="apellidos">Apellidos</label>
                              <input
                                type="text"
                                :disabled="opcion == 1"
                                class="form-control form-control-sm"
                                id="apellidos"
                                v-model="form.apellidos"
                                @input="validarLetra(form.nombres, 2)"
                                :class="
                                  $v.form.apellidos.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                            </div>
                            <div class="form-group col-md-2">
                              <label for="tipo_sangre">Tipo de Sangre</label>
                              <select
                                class="form-control form-control-sm"
                                :disabled="opcion == 1"
                                v-model="form.tipo_sangre"
                                :class="
                                  $v.form.tipo_sangre.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              >
                                <option value="">Seleccione...</option>
                                <option
                                  v-for="tipo_sangre in listasForms.tipos_sangre"
                                  :key="tipo_sangre.numeracion"
                                  :value="tipo_sangre.numeracion"
                                >
                                  {{ tipo_sangre.descripcion }}
                                </option>
                              </select>
                            </div>
                            <div
                              class="form-group"
                              :class="form.id ? 'col-md-3' : 'col-md-2'"
                            >
                              <label for="fecha_nacimiento"
                                >Fecha de nacimiento</label
                              >
                              <input
                                type="date"
                                :disabled="opcion == 1"
                                class="form-control form-control-sm"
                                id="fecha_nacimiento"
                                v-model="form.fecha_nacimiento"
                                :class="
                                  $v.form.fecha_nacimiento.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                @change="validaYears"
                              />
                            </div>
                            <div
                              class="form-group"
                              :class="form.id ? 'col-md-2' : 'col-md-1'"
                            >
                              <label for="edad"
                                >Edad <small>(años)</small></label
                              >
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                id="edad"
                                v-model="form.edad"
                                disabled
                              />
                            </div>
                            <div class="form-group col-md-3">
                              <label for="email">E-mail</label>
                              <input
                                type="email"
                                :disabled="opcion == 1"
                                class="form-control form-control-sm"
                                id="email"
                                v-model="form.email"
                                :class="
                                  $v.form.email.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                @blur="validaCorreo"
                              />
                            </div>
                            <div
                              class="form-group"
                              :class="form.id ? 'col-md-2' : 'col-md-3'"
                            >
                              <label for="celular_principal"
                                >Celular Principal</label
                              >
                              <input
                                type="number"
                                :disabled="opcion == 1"
                                class="form-control form-control-sm"
                                id="celular_principal"
                                v-model="form.celular_principal"
                                :class="
                                  $v.form.celular_principal.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                            </div>
                            <div
                              class="form-group"
                              :class="form.id ? 'col-md-2' : 'col-md-3'"
                            >
                              <label for="celular_alterno"
                                >Celular Alterno</label
                              >
                              <input
                                type="number"
                                :disabled="opcion == 1"
                                class="form-control form-control-sm"
                                id="celular_alterno"
                                v-model="form.celular_alterno"
                                :class="
                                  $v.form.celular_alterno.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                            </div>
                            <div class="form-group col-md-4">
                              <label for="area">Área</label>
                              <v-select
                                :class="[
                                  $v.form.area.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid',
                                  $store.getters.getDarkMode
                                    ? 'dark-vselect'
                                    : '',
                                ]"
                                v-model="area"
                                placeholder="Área"
                                label="descripcion"
                                class="form-control form-control-sm p-0"
                                :options="listasForms.tipos_area"
                                :filterable="true"
                                :disabled="opcion == 1"
                                @input="selectArea()"
                              ></v-select>
                            </div>
                            <div class="form-group col-md-2 text-center">
                              <label for="gerente_area">Gerente Área</label>
                              <div class="icheck-primary">
                                <input
                                  type="checkbox"
                                  id="gerente_area"
                                  :checked="form.gerente_area"
                                  v-model="form.gerente_area"
                                />
                                <label for="gerente_area"> </label>
                              </div>
                            </div>
                            <div class="form-group col-md-4">
                              <label for="cargo">Cargo</label>
                              <v-select
                                :class="[
                                  $v.form.cargo.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid',
                                  $store.getters.getDarkMode
                                    ? 'dark-vselect'
                                    : '',
                                ]"
                                v-model="cargo"
                                placeholder="Cargo"
                                label="descripcion"
                                class="form-control form-control-sm p-0"
                                :options="listasForms.tipos_cargo"
                                :filterable="true"
                                :disabled="opcion == 1"
                                @input="selectCargo()"
                              ></v-select>
                            </div>
                            <div class="form-group col-md-2 text-center">
                              <label for="admin_contrato">Admin Contrato</label>
                              <div class="icheck-primary">
                                <input
                                  type="checkbox"
                                  id="admin_contrato"
                                  :checked="form.admin_contrato"
                                  v-model="form.admin_contrato"
                                />
                                <label for="admin_contrato"> </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3" v-if="form.id">
                        <label for="imagen">Foto Funcionario</label>
                        <div
                          class="form-group"
                          v-if="form.link_fotografia == null"
                        >
                          <input
                            type="file"
                            :disabled="opcion == 1"
                            class="form-control-file"
                            accept="image/x-png,image/gif,image/jpeg"
                            @change="obtenerImagen"
                          />
                        </div>
                        <figure class="form-group" v-if="miniLogo">
                          <div class="button-container">
                            <img
                              :src="miniLogo"
                              alt="Logo"
                              width="236"
                              height="125"
                              class="rounded mx-auto d-block"
                            />
                            <a
                              :disabled="opcion == 1"
                              @click="destroyImage()"
                              id="BotonEliminar"
                              v-if="form.link_fotografia"
                              href="#"
                              >Eliminar <i class="fas fa-window-close"></i
                            ></a>
                          </div>
                        </figure>
                        <div class="row">
                          <div class="col-md-9"></div>
                          <div class="col-md-3" v-if="archivoValido">
                            <button
                              :disabled="opcion == 1"
                              type="button"
                              @click="saveImage()"
                              class="btn btn-success"
                              data-toggle="tooltip"
                              data-html="true"
                              title="Guardar Imagen"
                            >
                              <i class="fa fa-upload"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="Documentos" v-if="form.id">
                  <FuncionarioDocumento v-if="form.id" />
                </div>
              </div>

              <div class="card-footer">
                <div class="row">
                  <button
                    class="btn bg-primary col-md-1"
                    v-show="!$v.form.$invalid"
                    @click="save()"
                    :disabled="opcion == 1"
                  >
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>

                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import {
  required,
  numeric,
  minLength,
  maxLength,
  email,
} from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
import moment from "moment";
import vSelect from "vue-select";
import FuncionarioDocumento from "./FuncionarioDocumento";

export default {
  name: "FuncionarioForm",
  components: {
    Loading,
    vSelect,
    FuncionarioDocumento,
  },
  data() {
    return {
      cargando: false,
      form: {
        user_id: null,
        estado: null,
        cargo_f: null,
        area_f: null,
        numero_documento: null,
        admin_contrato: false,
        tipo_documento: null,
        nombres: null,
        apellidos: null,
        email: null,
        celular_principal: null,
        fecha_nacimiento: null,
        celular_alterno: null,
        area: null,
        edad: null,
        gerente_area: false,
        link_fotografia: null,
        cargo: null,
      },
      area_descripcion: null,
      cargo_descripcion: null,
      area: [],
      cargo: [],
      uploadPercentage: 0,
      uploadPercentageFoto: 0,
      miniLogo: null,
      archivoValido: false,
      fileFotografia: null,
      accion: "",
      opcion: null,
      metodo: "",
      listasForms: {
        tipo_identificacion: [],
        tipos_sangre: [],
        tipos_area: [],
        tipos_cargo: [],
        cargando: [],
      },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  validations: {
    form: {
      tipo_documento: {
        required,
      },
      numero_documento: {
        required,
        numeric,
        minLength: minLength(6),
        maxLength: maxLength(11),
      },
      nombres: {
        required,
        maxLength: maxLength(50),
      },
      apellidos: {
        required,
        maxLength: maxLength(50),
      },
      email: {
        required,
        email,
      },
      celular_principal: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      celular_alterno: {
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      area: {
        required,
      },
      cargo: {
        required,
      },
      fecha_nacimiento: {
        required,
      },
      admin_contrato: {
        required,
      },
      tipo_sangre: {
        required,
      },
    },
  },

  methods: {
    init() {
      this.accion = this.$route.params.accion;

      if (this.$route.params.accion == "Editar") {
        this.form = this.$route.params.data_edit;
        this.area = {
          numeracion: this.form.area,
          descripcion: this.form.area_f,
        };
        this.cargo = {
          numeracion: this.form.cargo,
          descripcion: this.form.cargo_f,
        };
        this.metodo = "PUT";
        this.miniLogo = this.uri_docs + this.form.link_fotografia;
        this.opcion = this.$route.params.opcion;
      } else {
        this.metodo = "POST";
        this.form.estado = 2;
      }
    },

    save() {
      this.link_fotografia = this.form.link_fotografia;
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        this.form.formulario = true;

        axios({
          method: this.metodo,
          url: "/api/admin/funcionarios",
          data: this.form,
        })
          .then((response) => {
            this.$route.params.accion = "Editar";
            this.$route.params.data_edit = response.data;
            this.$swal({
              icon: "success",
              title: "Se guardó el funcionario correctamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          })
          .catch((e) => {
            this.cargando = false;
            if (e.response.status == 500) {
              this.error = e.response.data.message;
              if (this.error.includes("1062 Duplicate entry")) {
                this.$swal({
                  icon: "error",
                  title:
                    "El número de documento ya se encuentra creado en la base de datos",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              } else {
                this.$swal({
                  icon: "error",
                  title: "Ha ocurrido un error, intentelo nuevamente",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              }
            } else {
              this.$swal({
                icon: "error",
                title:
                  "Ha ocurrido un error, por favor intente la acción nuevamente.",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            this.cargando = false;
          });
      }
    },

    validarLetra(palabra, opcion) {
      if (palabra.length > 0) {
        var ascii = palabra.toUpperCase().charCodeAt(palabra.length - 1);
        if (
          !(
            (ascii > 64 && ascii < 91) ||
            ascii == 32 ||
            ascii == 193 ||
            ascii == 201 ||
            ascii == 205 ||
            ascii == 211 ||
            ascii == 218
          )
        ) {
          if (opcion == 1) {
            this.form.nombres = palabra.slice(0, -1);
          }

          if (opcion == 2) {
            this.form.apellidos = palabra.slice(0, -1);
          }
        }
      }
    },

    async getTiposIdentificacion() {
      await axios.get("/api/lista/6").then((response) => {
        this.listasForms.tipo_identificacion = response.data;
      });
    },

    selectArea() {
      this.form.area = "";
      if (this.area) {
        this.form.area = this.area.numeracion;
        this.area_descripcion = this.area.descripcion;
        this.form.area_f = this.area.descripcion;
      }
    },
    selectCargo() {
      this.form.cargo = "";
      if (this.cargo) {
        this.form.cargo = this.cargo.numeracion;
        this.cargo_descripcion = this.cargo.descripcion;
        this.form.cargo_f = this.cargo.descripcion;
      }
    },
    async getTiposArea() {
      await axios.get("/api/lista/78").then((response) => {
        this.listasForms.tipos_area = response.data;
      });
    },

    async getTiposCargo() {
      await axios.get("/api/lista/77").then((response) => {
        this.listasForms.tipos_cargo = response.data;
      });
    },

    async getTiposSangre() {
      await axios.get("/api/lista/16").then((response) => {
        this.listasForms.tipos_sangre = response.data;
      });
    },

    validaFuncionario() {
      const numero_documento = this.form.numero_documento;
      if (numero_documento) {
        const me = this;
        this.cargando = true;
        const params = { numero_documento };
        axios
          .get("/api/admin/funcionarios/valida_funcionario", {
            params,
          })
          .then((response) => {
            this.cargando = false;
            if (response.data.msg_duplcate) {
              this.form.numero_documento = null;
              this.$swal({
                icon: "error",
                title: response.data.msg_duplcate,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            } else if (response.data.msg_correo) {
              this.form.numero_documento = null;
              this.$swal({
                icon: "error",
                title: response.data.msg_correo,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            } else {
              const funcionario = response.data;
              this.form.user_id = funcionario.id;
              this.form.tipo_documento = funcionario.type_document;
              this.form.nombres = funcionario.name;
              this.form.email = funcionario.email;
              this.form.celular_principal = funcionario.cel;
              this.form.link_fotografia = funcionario.link_photography;
            }
          });
      }
    },

    validaYears() {
      if (this.form.fecha_nacimiento) {
        var hoy = moment().format("YYYY");
        var fecha = moment(this.form.fecha_nacimiento).format("YYYY");
        var edad = hoy - fecha;
        this.form.edad = edad;
      }
    },

    validaCorreo() {
      const email = this.form.email;
      if (email) {
        const posicion = email.indexOf(`fronteraenergy.ca`);
        if (posicion === -1) {
          this.form.email = null;
          this.$swal({
            icon: "error",
            title: `E-mail no válido para crear un funcionario.`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    obtenerImagen(e) {
      $("#guard_foto").css("hide");
      this.miniLogo = null;
      this.archivoValido = false;
      if (e.target.files[0]) {
        this.cargando = true;
        const file = e.target.files[0];
        if (this.validaArchivo(file)) {
          this.archivoValido = true;
          this.fileFotografia = file;
          this.cargarImagen(file);
        }
      }
    },

    validaArchivo(file) {
      let flag = true;
      if (file.size > 2048000) {
        flag = false;
        this.fileFotografia = null;
        this.cargando = false;
        this.$swal({
          icon: "error",
          title: `El Archivo es muy pesado para subirlo`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return flag;
    },

    cargarImagen(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.miniLogo = e.target.result;
      };
      reader.readAsDataURL(file);
      this.cargando = false;
    },

    saveImage() {
      this.cargando = true;
      let formData = new FormData();
      formData.append("id", this.form.id);
      formData.append("link_fotografia", this.fileFotografia);
      axios
        .post("api/admin/funcionarios/foto", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.$swal({
            icon: "success",
            title: "Los datos se guardaron exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.archivoValido = false;
          this.form.link_fotografia = response.data.link_fotografia;
          this.cargando = false;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    // async getPropiedades() {
    //   await axios.get("/api/lista/23").then((response) => {
    //     this.listasForms.propiedades = response.data;
    //   });
    // },

    destroyImage() {
      this.$swal({
        title: "Esta seguro de eliminar esta Imagen?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          this.cargando = true;
          axios
            .delete("/api/admin/funcionarios/foto/" + this.form.id)
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "La imagen se elimino correctamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.cargando = false;
            })
            .catch(function(error) {
              this.$swal({
                icon: "error",
                title: "Ocurrio un error" + error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.cargando = false;
            });
          this.miniLogo = null;
          this.form.link_fotografia = null;
        }
      });
    },

    back() {
      return this.$router.replace("/Admin/Funcionarios");
    },
  },

  async mounted() {
    this.cargando = true;
    await this.getTiposIdentificacion();
    await this.getTiposSangre();
    await this.getTiposArea();
    await this.getTiposCargo();
    await this.init();
    this.cargando = false;
  },
};
</script>

<style>
.button-container {
  display: inline-block;
  position: relative;
}

.button-container a {
  bottom: 3em;
  right: 4em;
  color: white;
  text-transform: uppercase;
  padding: 1em 1.8em;
  opacity: 0.3;
  color: #fff !important;
  background: #ff0000;
  position: absolute;
  outline: 2px solid;
  outline-color: rgba(248, 8, 8, 0.89);
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}

.button-container a:hover {
  box-shadow: inset 0 0 20px rgba(248, 8, 8, 0.89),
    0 0 20px rgba(248, 8, 8, 0.89);
  outline-color: rgba(49, 138, 172, 0);
  outline-offset: 80px;
  text-shadow: 3px 3px 3px #fff;
}
</style>
